import Mention from '@tiptap/extension-mention'
import suggestion from './member_suggestion'

export const ExtendedMention = Mention.extend({
  name: 'memberMention',
  addOptions() {
    return {
      ...this.parent?.(),
      url: null,
    }
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      mentionMemberId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-mention-member-id'),
        renderHTML: (attributes) => {
          return { 'data-mention-member-id': attributes.id }
        },
      },
    }
  },
}).configure({
  HTMLAttributes: {
    class: 'mention ws-pre-wrapped',
  },
  suggestion,
})
