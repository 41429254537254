import { toTitleCaps } from '~/common/helpers/title_caps'

export class ToolbarActions {
  constructor(editor) {
    this.editor = editor
  }

  peformAction = (action) => {
    this.editor.chain().focus()[action]().run()
  }

  peformActionWithArg = (action, argument, options = {}) => {
    const focus = options.focus !== undefined ? options.focus : true

    let editorChain = this.editor.chain()
    if (focus) editorChain = editorChain.focus()

    editorChain[action](argument).run()
  }

  bold = () => {
    this.peformAction('toggleBold')
  }

  italic = () => {
    this.peformAction('toggleItalic')
  }

  strike = () => {
    this.peformAction('toggleStrike')
  }

  code = () => {
    this.peformAction('toggleCode')
  }

  paragraph = () => {
    this.peformAction('setParagraph')
  }

  h1 = () => {
    this.peformActionWithArg('toggleHeading', { level: 1 })
  }

  h2 = () => {
    this.peformActionWithArg('toggleHeading', { level: 2 })
  }

  h3 = () => {
    this.peformActionWithArg('toggleHeading', { level: 3 })
  }

  h4 = () => {
    this.peformActionWithArg('toggleHeading', { level: 4 })
  }

  h5 = () => {
    this.peformActionWithArg('toggleHeading', { level: 5 })
  }

  h6 = () => {
    this.peformActionWithArg('toggleHeading', { level: 6 })
  }

  bulletList = () => {
    this.peformAction('toggleBulletList')
  }

  orderedList = () => {
    this.peformAction('toggleOrderedList')
  }

  codeBlock = () => {
    this.peformAction('toggleCodeBlock')
  }

  blockquote = () => {
    this.peformAction('toggleBlockquote')
  }

  horizontalRule = () => {
    this.peformAction('setHorizontalRule')
  }

  undo = () => {
    this.peformAction('undo')
  }

  redo = () => {
    this.peformAction('redo')
  }

  addImage = (args) => {
    this.peformActionWithArg('setImage', args)
  }

  underline = () => {
    this.peformAction('toggleUnderline')
  }

  setLink = (args) => {
    this.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink(args)
      .command(({ tr }) => {
        if (args.text) {
          const { from, to } = tr.selection
          tr.insertText(args.text, from, to)
          tr.addMark(
            from,
            from + args.text.length,
            this.editor.schema.marks.link.create(args)
          )
        }
        return true
      })
      .run()
  }

  unsetLink = (_args) => {
    this.peformActionWithArg('unsetLink')
  }

  setHighlight = (args) => {
    this.peformActionWithArg('setHighlight', args)
  }

  setTextColor = (args) => {
    this.peformActionWithArg('setColor', args.color)
  }

  indentRight = () => {
    this.peformAction('indent')
  }

  indentLeft = () => {
    this.peformAction('outdent')
  }

  alignJustify = () => {
    this.peformActionWithArg('setTextAlign', 'justify')
  }

  alignCenter = () => {
    this.peformActionWithArg('setTextAlign', 'center')
  }

  alignLeft = () => {
    this.peformActionWithArg('setTextAlign', 'left')
  }

  alignRight = () => {
    this.peformActionWithArg('setTextAlign', 'right')
  }

  titleCaps = () => {
    const { view, state } = this.editor
    const { from, to } = view.state.selection
    let text = state.doc.textBetween(from, to, '')

    text = toTitleCaps(text)

    this.editor
      .chain()
      .focus()
      .insertContentAt(
        {
          from: from,
          to: to,
        },
        text
      )
      .run()
  }

  unsetColor = () => {
    this.peformAction('unsetColor')
  }

  unsetHighlight = () => {
    this.peformAction('unsetHighlight')
  }

  changeBulletIcon = (args, options = {}) => {
    this.peformActionWithArg('updateBulletIcon', args.faClasses, options)
  }

  setDecoration = (args, options = {}) => {
    this.peformActionWithArg('setDecoration', args, options)
  }

  unsetDecoration = () => {
    this.peformAction('unsetDecoration')
  }
}
