import $ from 'jquery'
import tippy from 'tippy.js'
import { PluginKey } from '@tiptap/pm/state'
import asyncDebounce from '../../../javascript/common/helpers/async_debounce'
import { getExtensionOption } from '../utilities'

export default {
  pluginKey: new PluginKey('memberMention'),
  allowSpaces: true,
  items: asyncDebounce(({ query, editor }) => {
    const url = getExtensionOption(editor, 'memberMention', 'url')
    if (!url) throw new Error('Member suggestion url is not set')
    return fetchSuggestions(url, query)
  }),
  render: () => {
    let popup
    let mentionsContainer

    return {
      onBeforeStart(obj) {
        const editorContainer = obj.editor.view.dom.closest(
          '.js-tiptap-editor-container'
        )
        mentionsContainer = document.createElement('div')
        mentionsContainer.className = 'mentions'
        editorContainer.append(mentionsContainer)
        const menuHTML = editorContainer.dataset['tiptap-EditorMenuValue']
        popup = tippy('body', {
          getReferenceClientRect: obj.clientRect,
          appendTo: () => mentionsContainer,
          content: createMenuDomElement(menuHTML),
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
          offset: [10, 0],
          popperOptions: {
            strategy: 'fixed',
          },
        })
      },
      onStart(obj) {
        setContentOnTippy(obj, popup, mentionsContainer)
      },
      onUpdate(obj) {
        setContentOnTippy(obj, popup, mentionsContainer)
      },
      onKeyDown(props) {
        const key = props.event.key
        const menuTarget = popup[0].popper.querySelector(
          "[data-controller='menu']"
        )
        if (key === 'Escape') {
          props.event.cancelBubble = true
          popup[0].hide()

          return true
        } else if (key === 'ArrowDown') {
          dispatchEventToMenu(menuTarget, 'menu:arrowDown')

          return true
        } else if (key === 'ArrowUp') {
          dispatchEventToMenu(menuTarget, 'menu:arrowUp')

          return true
        } else if (key === 'ArrowRight') {
          dispatchEventToMenu(menuTarget, 'menu:arrowRight')

          return true
        } else if (key === 'Enter') {
          dispatchEventToMenu(menuTarget, 'menu:enterKey')

          return true
        }
      },
      onExit() {
        popup[0].destroy()
        mentionsContainer.remove()
      },
    }
  },
}

const fetchSuggestions = async (url, query) => {
  let returnData
  await $.ajax({
    type: 'POST',
    url,
    data: { prefix: query, path: window.location.pathname },
    success: function (data) {
      returnData = data
    },
    error: function (_xhr, error, _message) {
      alert("We're sorry, we could not fetch the list of members.")
      console.log('error', error)
      returnData = ''
    },
  })
  return returnData
}

// Adding the event listener to anchor tag because MenuComponent selectActiveItem clicks anchor tag to select
// the item. Enter key and right arrow key shortcut also depends on that function
function removeSelectItemEventListener(container) {
  $(container).off('click', 'li a')
}

function addSelectItemEventListener(container, obj) {
  $(container).on('click', 'li a', function (e) {
    const dataset = e.currentTarget.closest('li').dataset
    obj.command({
      id: dataset.mentionMemberId,
      label: dataset.mentionMemberName,
    })
  })
}

function createMenuDomElement(innerHTML) {
  const menuDomObj = document.createElement('div')
  // bg-bg-alt - class sets the correct background color in dark and light mode
  menuDomObj.classList.add(
    'bg-bg-alt',
    'text-gray-800',
    'dark:text-gray-200',
    'rounded-lg',
    'text-sm',
    'shadow-lg',
    'dark:shadow-gray-850/15',
    'p-2',
    'max-h-132',
    'overflow-auto'
  )
  menuDomObj.innerHTML = innerHTML
  return menuDomObj
}

function dispatchEventToMenu(menuTarget, event) {
  menuTarget.dispatchEvent(new CustomEvent(event))
}

function setContentOnTippy(obj, popup, mentionsContainer) {
  removeSelectItemEventListener(mentionsContainer)
  const menuHTML = obj.items?.content
  if (menuHTML) {
    popup[0].setContent(createMenuDomElement(menuHTML))
  }
  addSelectItemEventListener(mentionsContainer, obj)
}
