// Inspired from here:
// https://github.com/evanfuture/tiptaptop-extension-indent/tree/main
// https://github.com/ueberdosis/tiptap/issues/1036
// The extension above adds a data-attribute and CSS for padding-left, we have instead
// added the padding-left directly to make it similar to TinyMCE so when we migrate
// we support existing indent and outdents
// Also, we have removed minLevel and maxLevel as TinyMCE didn't have that
import { Extension } from '@tiptap/core'
import { AllSelection, TextSelection } from 'prosemirror-state'

export const Indent = Extension.create({
  name: 'indent',

  addOptions: {
    types: ['listItem', 'paragraph'],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            renderHTML: (attributes) => {
              return attributes?.indent > 0
                ? { style: `padding-left: ${attributes.indent}px` }
                : null
            },
            parseHTML: (element) => {
              const paddingLeft = window
                .getComputedStyle(element, null)
                .getPropertyValue('padding-left')
              return paddingLeft && paddingLeft > 0 ? paddingLeft : null
            },
          },
        },
      },
    ]
  },

  addCommands() {
    const setNodeIndentMarkup = (tr, pos, delta) => {
      const node = tr?.doc?.nodeAt(pos)

      if (node) {
        const nextLevel = (node.attrs.indent || 0) + delta
        const indent = nextLevel <= 0 ? 0 : nextLevel

        if (indent !== node.attrs.indent) {
          const { indent: _oldIndent, ...currentAttrs } = node.attrs
          const nodeAttrs =
            indent > 0 ? { ...currentAttrs, indent } : currentAttrs
          return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks)
        }
      }
      return tr
    }

    const updateIndentLevel = (tr, delta) => {
      const { doc, selection } = tr

      if (
        doc &&
        selection &&
        (selection instanceof TextSelection ||
          selection instanceof AllSelection)
      ) {
        const { from, to } = selection
        doc.nodesBetween(from, to, (node, pos) => {
          if (this.options.types.includes(node.type.name)) {
            tr = setNodeIndentMarkup(tr, pos, delta)
            return false
          }

          return true
        })
      }

      return tr
    }
    const applyIndent =
      (direction) =>
      () =>
      ({ tr, state, dispatch }) => {
        const { selection } = state
        tr = tr.setSelection(selection)
        tr = updateIndentLevel(tr, direction)

        if (tr.docChanged) {
          dispatch?.(tr)
          return true
        }

        return false
      }

    return {
      indent: applyIndent(30),
      outdent: applyIndent(-30),
    }
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => this.editor.commands.indent(),
      'Shift-Tab': () => this.editor.commands.outdent(),
    }
  },
})
