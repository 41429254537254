import HardBreak from '@tiptap/extension-hard-break'

export const ExtendedHardBreak = HardBreak.extend({
  // This extension adds a shortcut for Mod-Enter which we are using for form submit, so override and add only one
  // keyboard shortcut for this extension
  addKeyboardShortcuts() {
    return {
      'Shift-Enter': () => this.editor.commands.setHardBreak(),
    }
  },
})
