import { Mark } from '@tiptap/core'
import { getAnimationConfig } from '~/controllers/text_decoration/base_controller'

export const Decoration = Mark.create({
  name: 'decoration',
  addAttributes() {
    return {
      config: {
        default: null,
        parseHTML: (element) =>
          JSON.parse(element.getAttribute('data-text-decoration-config')),
        renderHTML: (attributes) =>
          attributes.config
            ? {
                'data-text-decoration-config': JSON.stringify(
                  attributes.config
                ),
                'data-controller': `text-decoration--${attributes.config.style}`,
                'data-action': `text-decoration-restart-animation->text-decoration--${attributes.config.style}#restartAnimation`,
              }
            : {},
      },
    }
  },

  parseHTML() {
    return [{ tag: 'span[data-text-decoration-config]' }]
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['span', HTMLAttributes, 0]
  },

  addCommands() {
    return {
      setDecoration:
        (attributes) =>
        ({ commands, tr, view, state }) => {
          triggerAnimationIfNeeded(state.selection, tr.doc, view, attributes)
          return commands.setMark(this.name, attributes)
        },
      unsetDecoration:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        },
    }
  },
})

function triggerAnimationIfNeeded(selection, doc, view, attributes) {
  const { from, to } = selection
  let previousDecoratorConfig = null
  let previousDecoratorNode = null
  doc.nodesBetween(from, to, (node, pos) => {
    if (previousDecoratorConfig) return
    if (!node.isText) return
    if (!node.marks.some((mark) => mark.type.name === Decoration.name)) return

    previousDecoratorNode = view.nodeDOM(pos).parentNode
    previousDecoratorConfig = previousDecoratorNode.dataset.textDecorationConfig
  })

  if (!previousDecoratorConfig) return

  const oldConfig = JSON.parse(previousDecoratorConfig)
  const oldAnimationConfig = getAnimationConfig(oldConfig)
  const newAnimationConfig = getAnimationConfig(attributes.config)
  const different =
    JSON.stringify(oldAnimationConfig) != JSON.stringify(newAnimationConfig)

  if (different && newAnimationConfig.animation) {
    setTimeout(() => {
      let newDecoratorNode = null
      doc.nodesBetween(from, to, (node, pos) => {
        if (newDecoratorNode) return
        if (!node.isText) return
        if (!node.marks.some((mark) => mark.type.name === Decoration.name))
          return

        newDecoratorNode = view.nodeDOM(pos).parentNode
      })

      newDecoratorNode.dispatchEvent(
        new CustomEvent('text-decoration-restart-animation')
      )
    })
  }
}
