// The debounce function we have doesn't work with async correctly, this function is an alternative which supports debounce with async
// From: https://github.com/lodash/lodash/issues/4815#issuecomment-859379489

import { debounce } from 'throttle-debounce'

export default function asyncDebounce(func, wait = 300) {
  const debounced = debounce(wait, async (resolve, reject, bindSelf, args) => {
    try {
      const result = await func.bind(bindSelf)(...args)
      resolve(result)
    } catch (error) {
      reject(error)
    }
  })

  // This is the function that will be bound by the caller, so it must contain the `function` keyword.
  function returnFunc(...args) {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, this, args)
    })
  }

  return returnFunc
}
