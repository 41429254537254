import { Extension } from '@tiptap/core'

export const FormSubmit = Extension.create({
  name: 'formSubmit',

  addKeyboardShortcuts() {
    return {
      'Mod-Enter': () =>
        this.editor.view.dom
          .closest('form')
          ?.querySelector('[type=submit]')
          .click(),
    }
  },
})
