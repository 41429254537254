import { Backspace } from './extensions/backspace'
import Bold from '@tiptap/extension-bold'
import Blockquote from '@tiptap/extension-blockquote'
import BubbleMenu from '@tiptap/extension-bubble-menu'
import BulletList from '@tiptap/extension-bullet-list'
import Code from '@tiptap/extension-code'
import CodeBlock from '@tiptap/extension-code-block'
import Color from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import { ExtendedHardBreak } from './extensions/hard_break'
import { ExtendedMention } from './extensions/mention'
import { FaListItem } from './extensions/fa_list_item'
import Focus from '@tiptap/extension-focus'
import { FormSubmit } from './extensions/form_submit'
import Gapcursor from '@tiptap/extension-gapcursor'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import Highlight from '@tiptap/extension-highlight'
import History from '@tiptap/extension-history'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Image from '@tiptap/extension-image'
import { Indent } from './extensions/indent'
import Italic from '@tiptap/extension-italic'
import ListKeymap from '@tiptap/extension-list-keymap'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import { extendLinkExtension } from './extensions/simplero_link'
import Strike from '@tiptap/extension-strike'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { Decoration } from './extensions/decoration'

export function configuredExtensions(editorController) {
  const extensions = [
    Backspace,
    Bold,
    Blockquote,
    BubbleMenu.configure({
      element: editorController.toolbarTarget,
      tippyOptions: {
        detachOnUnmount: false,
        maxWidth: 'none',
        hideOnClick: true,
        onShown(instance) {
          instance.popper.style.maxHeight = ''
          editorController.fireSetTextColorEvent()
          editorController.fireSetHighlightColorEvent()
          editorController.fireSetDecorationFromEditorEvent()
          editorController.toolbarTarget.style.visibility = 'visible'
        },
        onHidden(instance) {
          // We don't remove the tippy element from DOM and it is hidden with visibility: hidden which takes up space on the DOM
          // So setting max-height: 0 removes that whitespace
          // As tippy uses transitions - display: none causes issues and cannot be used
          instance.popper.style.maxHeight = 0
          editorController.toolbarTarget.style.visibility = 'hidden'
        },
        popperOptions: {
          strategy: 'fixed',
        },
      },
    }),
    BulletList.configure({
      keepMarks: true,
      keepAttributes: true,
      HTMLAttributes: {
        class: editorController.forBulletListValue ? 'fa-bullet-list' : null,
      },
    }),
    Code,
    CodeBlock,
    Color.configure({
      types: ['textStyle'],
    }),
    doumentNode(editorController),
    Decoration,
    Dropcursor,
    Focus.configure({
      mode: 'deepest',
    }),
    Gapcursor,
    editorController.formSubmitShortcutValue ? ExtendedHardBreak : HardBreak,
    extendedHeadingNode(editorController),
    Highlight.configure({
      multicolor: true,
      parseHTML() {
        return [
          {
            tag: 'span',
          },
        ]
      },
    }),
    History,
    HorizontalRule,
    Indent,
    Italic,
    listItem(editorController),
    OrderedList,
    Paragraph,
    extendLinkExtension(editorController).configure({
      openOnClick: false,
    }),
    Placeholder.configure({
      placeholder: editorController.placeholderValue,
    }),
    Strike,
    Table.configure({
      resizable: true,
    }),
    TableCell,
    TableHeader,
    TableRow,
    Text,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    TextStyle,
    Underline,
  ]

  if (editorController.formSubmitShortcutValue) {
    extensions.push(FormSubmit)
  }

  if (editorController.mentionsUrlValue) {
    extensions.push(
      ExtendedMention.configure({ url: editorController.mentionsUrlValue })
    )
  }

  if (editorController.withImageValue) {
    extensions.push(
      Image.configure({
        addAttributes() {
          return {
            src: {
              default: null,
            },
            width: {
              default: null,
            },
            height: {
              default: null,
            },
            alt: {
              default: null,
            },
          }
        },
      })
    )
  }

  if (editorController.forBulletListValue) {
    extensions.push(ListKeymap)
  }

  return extensions
}

function listItem(editorController) {
  if (editorController.forBulletListValue) {
    if (editorController.listIconValue) {
      return FaListItem.configure({
        HTMLAttributes: { iconClass: editorController.listIconValue },
      })
    } else {
      return FaListItem
    }
  } else {
    return ListItem
  }
}

// Extend the heading node to assign it to a different group than "block".
// This ensures that the documentNode function allows only one heading element.
// If heading remains in the "block" group, multiple headings would be allowed because "block*" permits it.
function extendedHeadingNode(editorController) {
  if (editorController.forHeadingValue) {
    return Heading.extend({
      group: 'heading',
    })
  } else {
    return Heading
  }
}

function doumentNode(editorController) {
  let contentType

  if (editorController.forHeadingValue) {
    // We want to allow only one heading tag on the headline elements on builder
    contentType = 'heading block*'
  } else if (editorController.forBulletListValue) {
    contentType = 'bulletList'
  }

  if (contentType) {
    return Document.extend({
      content: contentType,
    })
  } else {
    return Document
  }
}
