import Link from '@tiptap/extension-link'

export const extendLinkExtension = (editorController) =>
  Link.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        dataInternalUri: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-internal-uri'),
          renderHTML: (attributes) => {
            return attributes.dataInternalUri
              ? { 'data-internal-uri': attributes.dataInternalUri }
              : {}
          },
        },
        dataMagicLogin: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-magic-login'),
          renderHTML: (attributes) => {
            return attributes.dataMagicLogin
              ? { 'data-magic-login': attributes.dataMagicLogin }
              : {}
          },
        },
        title: {
          default: null,
          parseHTML: (element) => element.getAttribute('title'),
          renderHTML: (attributes) => {
            return attributes.title
              ? {
                  title: attributes.title,
                }
              : {}
          },
        },
      }
    },
    addKeyboardShortcuts() {
      return {
        'Mod-k': () => editorController.openLinkPicker(true),
      }
    },
    inclusive: false,
  })
