import { Extension } from '@tiptap/core'

// This extension helps to enhance the backspace key functionality
// If the cursor is on start position of WYSIWYG and it has no text and there is a bullet list below
// then pressing backspace should remove the empty paragraph element and pull the bullet list above on first line
// This is same as pressing the foward delete key - fn+delete (MacOS) or delete (other OS) but as MacOS users might
// not know the key combination for forward delete - this should make their life easier
export const Backspace = Extension.create({
  name: 'backspace',

  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const { from, to } = editor.state.selection
        if (from === 1 && to === 1) {
          const dom = editor.view.dom
          const firstChild = dom.querySelector(':first-child')
          if (firstChild.classList.contains('is-empty')) {
            firstChild.remove()
          }
        }
      },
    }
  },
})
