import ListItem from '@tiptap/extension-list-item'

import { ListItemView } from '../node_views/list_item_view'
import { mergeAttributes } from '@tiptap/core'

export const FaListItem = ListItem.extend({
  content: 'inline*',
  addAttributes() {
    return {
      iconClass: {
        default: 'fa-solid fa-circle',
        parseHTML: (element) => element.getAttribute('data-icon-class'),
        renderHTML: (attributes) => {
          return { 'data-icon-class': attributes.iconClass }
        },
      },
    }
  },
  addCommands() {
    return {
      updateBulletIcon:
        (classes) =>
        ({ tr, _state, dispatch }) => {
          const { doc } = tr

          // Exclude the start and end token
          const nodeSize = doc.nodeSize - 2
          if (doc) {
            doc.nodesBetween(0, nodeSize, (node, pos) => {
              if (
                node.type.name === 'listItem' &&
                node.attrs.iconClass !== classes
              ) {
                tr.setNodeMarkup(pos, null, { iconClass: classes }, node.marks)
              }
            })
          }

          if (tr.docChanged) {
            dispatch?.(tr)
            return true
          }

          return false
        },
    }
  },
  renderHTML({ node, HTMLAttributes, getPos, editor }) {
    return new ListItemView(
      node,
      mergeAttributes(HTMLAttributes, this.options.HTMLAttributes),
      getPos,
      editor
    )
  },
  addNodeView() {
    return ({ node, HTMLAttributes, getPos, editor }) => {
      return new ListItemView(
        node,
        mergeAttributes(HTMLAttributes, this.options.HTMLAttributes),
        getPos,
        editor
      )
    }
  },
})
