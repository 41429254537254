import crel from 'crelt'

export class ListItemView {
  constructor(node, HTMLAttributes, _getPos, _editor) {
    this.node = node
    const editableDiv = crel('div', { class: 'content' })
    this.dom = crel('li', { 'data-icon-class': HTMLAttributes.iconClass }, [
      crel('span', { class: 'icon', contentEditable: false }, [
        crel('i', { class: HTMLAttributes.iconClass, 'aria-hidden': true }),
      ]),
      editableDiv,
    ])
    this.contentDOM = editableDiv
  }

  ignoreMutation(mutation) {
    return mutation.target.tagName === 'I'
  }
}
